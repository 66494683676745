import { Link, NavLink } from "react-router-dom";
import './footer.scss';

import facebook from '../assets/icons/Facebook.svg';
import linkedIn from '../assets/icons/LinkedIn.svg';
import twitter from '../assets/icons/Twitter.svg';

const Footer = () => {
    return (
        <footer>
            <section className='get_started py-5'>
                <div className='container'>
                    <div className='d-flex flex-wrap justify-content-between align-items-center'>
                        <div className='content col-12 col-md-7'>
                            <p className='header '>Get started today</p>
                            <p className='header-content grey'>Inventory financing built for small retailers in Africa</p>
                        </div>
                        <div className='action col-12 col-md-2'>
                            <Link to="/contact-us">
                                <button className='button-primary' type='button'>Get Started</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className='info'>
                <div className='container'>
                    <div className='d-flex justify-content-between mt-5 flex-wrap'>
                        <div className='col-12 col-md-6 col-lg-3 mb-5 mb-lg-0'>
                            <NavLink to="/" className="mb-xs-1 mb-md-0">
                                <img className="brand" src="zanifu.png" alt="Nav-Logo" width="30" height="24" />
                            </NavLink>
                            <p className="spaced-content my-4">Zanifu makes it possible for millions of African businesses to access financial services</p>
                            <div className="d-flex">
                                <a href="https://www.facebook.com/ZanifuLtd/" target="_blank" rel="noreferrer">
                                    <img className="icon me-3" src={ facebook } alt="facebook icon" />
                                </a>
                                <a href="https://twitter.com/ZanifuLtd" target="_blank" rel="noreferrer">
                                    <img className="icon me-3" src={ twitter } alt="twitter icon" />
                                </a>
                                <a href="https://www.linkedin.com/company/zanifu/mycompany/" target="_blank" rel="noreferrer">
                                    <img className="icon" src={ linkedIn } alt="linkedIn icon" />
                                </a>
                            </div>
                        </div>
                        <div className='col-12 col-md-5 col-lg-2 mb-5 mb-lg-0'>
                            <p className="footer-heading mb-4">Company</p>
                            <NavLink to="/" className="nav-link">
                                Home
                            </NavLink>
                            <NavLink to="/careers" className="nav-link">
                                Careers
                            </NavLink>
                            <NavLink to="/blog" className="nav-link">
                                Blog
                            </NavLink>
                        </div>
                        <div className='col-12 col-md-6 col-lg-2 mb-5 mb-lg-0'>
                            <p className="footer-heading mb-4">Policies and resources</p>
                            <NavLink to="/privacy-policy" className="nav-link">
                                Privacy Policy
                            </NavLink>
                            <NavLink to="/terms-and-conditions" className="nav-link">
                                Terms and conditions
                            </NavLink>
                        </div>
                        <div className='col-12 col-md-5 col-lg-2 mb-5 mb-lg-0'>
                            <p className="footer-heading mb-4">Contact Us</p>
                            <p>Kenrail Towers</p>
                            <p>Nairobi, Kenya</p>
                            <p>Email: <a href="mailto:info@anifu.com">info@zanifu.com</a></p>
                            <p>Phone: <a href="tel:254726091857">+254726091857</a></p>
                        </div>
                    </div>
                </div>
            </section>
            <section className=''>
                <div className='container'>
                    <hr className="my-4" />
                    <p className="copyright mt-2 mb-4">	&copy; 2022 zanifu.com All rights reserved</p>
                </div>
            </section>
        </footer>
    )
};

export default Footer;