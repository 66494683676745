import { useState } from 'react';
import ReactCustomFlagSelect from 'react-custom-flag-select';
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'services/axios';
import { Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import mixpanel from 'mixpanel-browser';

import facebook from '../assets/icons/Facebook.svg';
import linkedIn from '../assets/icons/LinkedIn.svg';
import twitter from '../assets/icons/Twitter.svg';

import ke from '../assets/images/flags/ke.svg';
import tz from '../assets/images/flags/tz.svg';
import ug from '../assets/images/flags/ug.svg';
import { useEffectOnce } from 'hooks/useEffectOnce';

const FLAG_SELECTOR_OPTION_LIST = [
    { id: '254', name: 'KE', displayText: 'KE(254)', locale: 'en-ke', englishName: 'Kenya', flag: ke },
    { id: '255', name: 'TZ', displayText: 'TZ(255)', locale: 'sw-tz', englishName: 'Tanzania', flag: tz },
    { id: '256', name: 'UG', displayText: 'UG(256)', locale: 'lg-ug', englishName: 'Uganda', flag: ug }
];

const ContactUs = () => {

    const [areaCode, setAreaCode] = useState(254);
    const [isLoading, setIsLoading] = useState(false);

    const initialValues = {
        name: '',
        phone: '',
        email: '',
        message: ''
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        phone: Yup.string()
            .min(9, 'Phone number is not valid')
            .max(9, 'Phone number is not valid')
            .required('Phone is required'),
        email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email Address is required'),
        message: Yup.string()
            .required('Message is required'),
    });

    const { errors, values, touched, handleBlur, handleChange, handleSubmit } = 
        useFormik({
            initialValues,
            validationSchema,
            onSubmit: async (values) => {
                setIsLoading(true);
                const body = {
                    name: values.name,
                    phone: `+${areaCode}${values.phone}`,
                    email: values.email,
                    message: values.message,
                }
                mixpanel.track('Send Message', { page: 'Contact Us' });

                try{
                    const res = await axios.post('./contact-us', body);
                    setIsLoading(false);

                    if (res.status === 200) {
                        toast.success('Message successfully sent', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            });
                    }
                } catch(err) {
                    setIsLoading(false);
                    toast.error('Oops! Something Wrong happened. Try again later.', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        });

        
    function handleAreaCodeCHange (input: any) {
        setAreaCode(input);
    }
    useEffectOnce(() => {
        mixpanel.track('Page View', { page: 'Contact Us' });
    });
        
    return (
        <div>
            <section className="light-grey py-em-5">
                <div className="container">
                    <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <div style={{ maxWidth: '568px' }}>
                            <h1 className='contact-header m-0'>We'd love to hear from you</h1>
                            <p className="contact-banner">Are you a distributor or manufacturer looking to improve your cashflow & increase your sales</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container py-em-5">
                <div className="d-flex flex-wrap justify-content-center align-items-start">
                    <div className="col-12 col-md-5">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Name</label>
                                <input className={`field-input ${Boolean(touched.name && errors.name) ? "error-field": ""}`} name="name" type="text" placeholder="Enter your name"
                                    onBlur={handleBlur} onChange={handleChange} value={values.name} />
                                {Boolean(touched.name && errors.name) &&
                                    <p className='error mt-2'> { errors.name }</p>
                                }
                            </div>

                            <div className="form-group">
                                <label>Phone Number</label>
                                <div className={`field-input phone-input d-flex align-items-center ${Boolean(touched.phone && errors.phone) ? "error-field": ""}`}>
                                    <ReactCustomFlagSelect
                                        attributesWrapper={{ id: 'areaCodeWrapper' }}
                                        attributesButton={{ id: 'areaCodeButton' }}
                                        attributesInput={{ id: 'areaCode' }}
                                        value={areaCode}
                                        disabled={false}
                                        showSearch={true}
                                        fields={['name', 'locale', 'displayText', 'englishName']}
                                        showArrow={true}
                                        animate={true}
                                        optionList={FLAG_SELECTOR_OPTION_LIST} 
                                        customStyleContainer={{ border: 'none', fontSize: '12px' }}
                                        customStyleSelect={{ width: '100px', color: '#000' }}
                                        customStyleOptionListContainer={{ maxHeight: '100px', overflow: 'auto', width: '120px', marginTop: '11px' }} 
                                        onChange={areaCode => {
                                            handleAreaCodeCHange(areaCode);
                                        }}
                                    />
                                    <input className="" name="phone" type="text" placeholder="Enter phone number"
                                        onBlur={handleBlur} onChange={handleChange} value={values.phone} />
                                </div>
                                <p className='hint'>Number should be in the format: 7xxxxxxxx or 1xxxxxxxx</p>
                                {Boolean(touched.phone && errors.phone) &&
                                    <p className='error mt-1'> { errors.phone }</p>
                                }
                            </div>
                            <div className="form-group">
                                <label>Email Address</label>
                                <input className={`field-input ${Boolean(touched.email && errors.email) ? "error-field": ""}`} name="email" type="email" placeholder="Enter email address" 
                                    onBlur={handleBlur} onChange={handleChange} value={values.email} />
                                {Boolean(touched.email && errors.email) &&
                                    <p className='error mt-2'> { errors.email }</p>
                                }
                            </div>
                            <div className="form-group">
                                <label>Message</label>
                                <textarea className={`field-input ${Boolean(touched.message && errors.message) ? "error-field": ""}`} rows={4} name="message" placeholder="Enter message"
                                    onBlur={handleBlur} onChange={handleChange} value={values.message}></textarea>
                                {Boolean(touched.message && errors.message) &&
                                <p className='error mt-2'> { errors.message }</p>
                                }
                            </div>
                            <button className='button-primary full-width mt-md-5' type='submit' disabled={isLoading}>
                                {isLoading ?
                                    <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"/>
                                    :
                                    <>Send Message</>
                                }
                            </button>
                            <ToastContainer position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover />
                        </form>
                    </div>
                    <div className="col-12 col-md-3 ps-md-5 ms-md-5 mt-5 mt-md-0">
                        <div className='contact-wrapper mb-5'>
                            <p className='contact-label m-0'>Email Address</p>
                            <a className='contact-detail' href="mailto:info@zanifu.com">info@zanifu.com</a>
                        </div>
                        <div className='contact-wrapper mb-5'>
                            <p className='contact-label m-0'>Phone Number</p>
                            <a className='contact-detail' href="tel:254726091857">+254726091857</a>
                        </div>
                        <div className='contact-wrapper'>
                            <p className='contact-label m-0'>Address</p>
                            <p className='contact-detail'>Westlands, Nairobi</p>
                        </div>
                        <div className="d-flex">
                            <a href="https://www.facebook.com/ZanifuLtd/" target="_blank" rel="noreferrer">
                                <img className="icon me-3" src={ facebook } alt="facebook icon" />
                            </a>
                            <a href="https://twitter.com/ZanifuLtd" target="_blank" rel="noreferrer">
                                <img className="icon me-3" src={ twitter } alt="twitter icon" />
                            </a>
                            <a href="https://www.linkedin.com/company/zanifu/mycompany/" target="_blank" rel="noreferrer">
                                <img className="icon" src={ linkedIn } alt="linkedIn icon" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default ContactUs;